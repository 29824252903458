
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/fluture.png'
import "../css/ImgInvitatie.css"
import background from "../images/h3.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" style={{ backgroundImage: `url(${background})` }}>
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4"  >
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <h5>
                            Sunt frumoasă, prețioasă, <br />
                            De părinți super iubită!<br />
                            Am venit în a lor casă<br />
                            Și familia e întregită!<br />
                        </h5>
                        <h5>
                            Frățiorul mă răsfață,<br />
                            Aduc zâmbete pe față…<br />
                            Toți mă iubesc de zor<br />
                            Fiindcă sunt prințesa lor!<br />
                        </h5>

                        <h5>
                            Cu mult drag vă Invit la petrecerea mea,<br />
                            care va avea loc
                        </h5>

                        <h4>Vineri, 11 August 2023, ora 18:00</h4>
                        <h4>Restaurantul „Lacul Verde”</h4>
                        <h5>Strada Chişinăului 5a, Străşeni</h5>



                        <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                        <h4>Cu drag, Cataleya</h4>



                        <img id="mikey_img" src={mikey} />




                        {/** <ImgInvitatie />*/}

                        <h4 class="card-footer">Vă așteptăm cu nerăbdare, de la mic și pân la mare!</h4>
                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>
                    </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;